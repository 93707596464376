import {classes} from './flex-layout.st.css';

export function flexColumn () {
    return classes.flexColumnDefault;
}

export function flexColumnAlignToStart () {
    return classes.flexColumnAlignToStart;
}

export function flexColumnAlignToEnd () {
    return classes.flexColumnAlignToEnd;
}

export function flexColumnAlignToCenter () {
    return classes.flexColumnAlignToCenter;
}

export function flexRowAlignToStart () {
    return classes.flexRowAlignToStart;
}

export function flexRowAlignToCenter () {
    return classes.flexRowAlignToCenter;
}

export function flexRowAlignToEnd () {
    return classes.flexRowAlignToEnd;
}

export function flexFillRemainingHorizontalSpace () {
    return classes.flexFillRemainingHorizontalSpace;
}

export function flexFillRemainingVerticalSpace () {
    return classes.flexFillRemainingVerticalSpace;
}

export function flexCenterContent () {
    return classes.flexCenterContent;
}

export function flexNoShrink () {
    return classes.noShrink;
}

export function flexShrinkInRemainingHorizontalSpace () {
    return classes.shrinkInRemainingHorizontalSpace;
}

export function flexShrinkInRemainingVerticalSpace () {
    return classes.shrinkInRemainingVerticalSpace;
}

export function flexHorizontallyJustifyToEnd () {
    return classes.flexHorizontallyJustifyToEnd;
}

export function flexVerticallyJustifyToEnd () {
    return classes.flexVerticallyJustifyToEnd;
}

export function flexEvenlyDistributeChildren () {
    return classes.flexEvenlyDistributeChildren;
}

export function flexWrap () {
    return classes.flexWrap;
}

export function flexJustifyContentSpaceBetween () {
    return classes.justifyContentSpaceBetween;
}

export function flexJustifyContentCenter () {
    return classes.justifyContentCenter;
}

export function flexJustifyContentToEnd () {
    return classes.justifyContentToEnd;
}

export function flexJustifyContentToStart () {
    return classes.justifyContentToStart;
}

export function flexJustifySelfToCenter () {
    return classes.flexJustifySelfCenter;
}

export function flexAlignSelfToStart () {
    return classes.flexAlignSelfFlexStart;
}

export function flexAlignSelfToEnd () {
    return classes.flexAlignSelfFlexEnd;
}

export function flexAlignSelfToCenter () {
    return classes.flexAlignSelfCenter;
}

export function flexAlignSelfAsStretched () {
    return classes.flexAlignSelfStretch;
}